import { useTranslation } from "react-i18next";

import { useLangUrlDefault } from "../../../../../hooks";
import {
  StyledCirkle,
  StyledContentList,
  StyledContentListItem,
  StyledContentTitle
} from "../../ExpandableComponent.styled";

export const ProxyTypes = ({ data }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const proxyTypes =
    data?.proxyTypeProps?.length > 0
      ? data.proxyTypeProps
          ?.map((type) => ({
            label:
              type?.proxyTypeLocalization?.[queryLang] ||
              type?.proxyTypeLocalization?.en ||
              type?.proxyType,
            value: type.proxyTypeId
          }))
          ?.filter((it) => it.label && it.value)
      : [];

  return (
    <div>
      <StyledContentTitle>
        {t("proxyPage.content.table.type.title")}
      </StyledContentTitle>
      {proxyTypes?.length > 0 ? (
        <StyledContentList>
          {proxyTypes
            .map((type) => (
              <StyledContentListItem key={type?.value}>
                <StyledCirkle />
                {type?.label}
              </StyledContentListItem>
            ))
            .filter(Boolean)}
        </StyledContentList>
      ) : (
        <span>{t("proxyPage.content.table.type.noContent")}</span>
      )}
    </div>
  );
};
