import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { getCountriesById } from "../../../../../utils/helpers";
import { AllProxyCountriesModal } from "../../../Modals/AllProxyCountriesModal/AllProxyCountriesModal";
import {
  StyledContentList,
  StyledContentListItem,
  StyledContentTitle,
  StyledTextButton
} from "../../ExpandableComponent.styled";

export const Locations = ({ data }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const proxyType = searchParams.get("fpt") || "IPv4";

  // **Redux state
  const { countries } = useSelector(getAllContent);
  const [open, setOpen] = useState(false);

  let geo = [];
  if (data?.proxyTypeProps?.length > 0) {
    geo =
      data.proxyTypeProps?.find((item) => item.proxyType === proxyType)
        ?.countryIds || [];
  }

  const countriesList = getCountriesById(geo, countries.data);

  if (countriesList?.length === 0) return null;

  const toggleModal = () => setOpen((prev) => !prev);

  return (
    <div>
      <StyledContentTitle>
        {t("proxyPage.content.table.geo.title")}
      </StyledContentTitle>
      <StyledContentList>
        <StyledContentListItem>
          {countriesList?.length} {t("proxyPage.content.table.geo.title")}
        </StyledContentListItem>
        <StyledContentListItem>
          <StyledTextButton
            color={"secondary"}
            size={"sm"}
            onClick={toggleModal}
          >
            {t("proxyPage.content.table.geo.modal.title")}
          </StyledTextButton>
        </StyledContentListItem>
      </StyledContentList>
      <AllProxyCountriesModal
        data={countriesList}
        open={open}
        onClose={setOpen}
      />
    </div>
  );
};
