export const RATING_CHART_OPTIONS = {
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      border: {
        display: false
      },
      grid: {
        display: false
      },
      ticks: {
        color: "#C5CDD9",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%"
      }
    },
    y: {
      suggestedMin: 3.0,

      suggestedMax: 5.0,
      ticks: {
        color: "#C5CDD9",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%"
      },
      grid: {
        color: "#F2F4F7"
      },
      border: {
        display: false
      }
    }
  }
};
