import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import profileIcon from "../../../../assets/img/dashboard/nav/profile.svg";
import promocodesIcon from "../../../../assets/img/dashboard/nav/promocodes.svg";
import reviewsIcon from "../../../../assets/img/dashboard/nav/reviews.svg";
import servicesIcon from "../../../../assets/img/dashboard/nav/services.svg";
import {
  useDispatchedActions,
  useLangUrlDefault,
  useLockBackdrop
} from "../../../../hooks";
import { ApiService } from "../../../../services";
import { getAllUser } from "../../../../store/reducers/UserReducer/User.selectors";

import { LangSelect } from "./Lang/LangSelect";
import {
  ButtonCreate,
  ButtonLogout,
  ButtonMobileMenu,
  LogoutTitle,
  Menu,
  MenuBackDrop,
  MenuLogout,
  MenuWrapper,
  MobileCenterMenuSeparator,
  RestyledLogo,
  StyledNavLink,
  UserEmail,
  StyledLogoMobile
} from "./Menu.styled";

const CHANGE_TYPE = {
  default: "addSite",
  addSite: "default",
  logout: "default"
};

export const DashboardMenu = ({ type, setType }) => {
  const [, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const prefix = `${hrefLang}/dashboard`;
  const { user } = useSelector(getAllUser);
  // **Dispatch
  const { setLogout, setMobileMenu, resetCabinet } = useDispatchedActions();

  const handleOpenAddSite = () => {
    if (window.innerWidth < 992) {
      setType(CHANGE_TYPE[type]);
    }
  };

  const logout = async () => {
    setType("default");
    try {
      const response = await ApiService.logout();

      if (response && response.status !== 200) {
        throw response;
      }

      navigate(`${hrefLang}/`, { replace: true });
      setMobileMenu(false);
      setLogout();
      resetCabinet();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error: ", err?.response || err?.response || err);
    }
  };

  const render = useMemo(
    () => (
      <>
        <MenuBackDrop
          open={type !== "default"}
          onClick={() => setType("default")}
        />
        <MenuWrapper type={type}>
          <RestyledLogo />
          {type === "default" && (
            <>
              <Menu className={"scrollbar__dark"}>
                <StyledNavLink to={`${prefix}/profile/`}>
                  <img src={profileIcon} alt={t("dashboard.navBar.profile")} />
                  <span>{t("dashboard.navBar.profile")}</span>
                </StyledNavLink>
                <StyledNavLink to={`${prefix}/services/`}>
                  <img
                    src={servicesIcon}
                    alt={t("dashboard.navBar.services")}
                  />
                  <span> {t("dashboard.navBar.services")}</span>
                </StyledNavLink>
                <MobileCenterMenuSeparator />
                <StyledNavLink to={`${prefix}/reviews/`}>
                  <img src={reviewsIcon} alt={t("dashboard.navBar.reviews")} />
                  <span> {t("dashboard.navBar.reviews")}</span>
                </StyledNavLink>
                <StyledNavLink to={`${prefix}/promocodes/`}>
                  <img
                    src={promocodesIcon}
                    alt={t("dashboard.navBar.promocodes")}
                  />
                  <span>{t("dashboard.navBar.promocodes")}</span>
                </StyledNavLink>
              </Menu>
            </>
          )}
          {type === "logout" && (
            <MenuLogout>
              <StyledLogoMobile />
              <LogoutTitle>{t("dashboard.navBar.hello")}</LogoutTitle>
              <UserEmail>{user?.email}</UserEmail>
              <LangSelect />
              <ButtonLogout fullWidth iconRight={"logout"} onClick={logout}>
                {t("dashboard.navBar.logout")}
              </ButtonLogout>
            </MenuLogout>
          )}

          <ButtonCreate
            visible={type === "addSite"}
            variant={"primary"}
            size={"md"}
            iconLeft={"plus2"}
            linkTo={`${prefix}/services/create/`}
            onClick={handleOpenAddSite}
          >
            {t("dashboard.navBar.addSite")}
          </ButtonCreate>

          {type !== "logout" ? (
            <ButtonMobileMenu
              open={type !== "default"}
              variant={"primary"}
              size={"md"}
              iconLeft={"plus2"}
              onClick={handleOpenAddSite}
            />
          ) : (
            <ButtonMobileMenu
              menuType={"logout"}
              variant={"primary"}
              size={"md"}
              onClick={handleOpenAddSite}
            >
              {user?.email.charAt(0).toUpperCase()}
            </ButtonMobileMenu>
          )}
        </MenuWrapper>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, prefix, user]
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992 && type !== "default") {
        setType("default");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [type, setType]);

  useLockBackdrop(type !== "default");
  return render;
};
