import styled from "@emotion/styled";
import { Logo } from "../../components/ui/Logo/Logo";

export const DashboardLayoutStyled = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  background-color: ${({ theme }) => theme.colors["background-color-seo"]};
  min-height: 100vh;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    flex-direction: row;
  }
`;

export const DashboardLayoutContent = styled.div`
  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    width: 100%;
    background-color: ${({ theme }) => theme.colors["background-color"]};
  }
`;

export const SpaceContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 0 16px;
  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    min-height: calc(100vh - 77px);
    margin-left: 240px;
    padding: 40px 48px 120px 48px;
  }
`;

export const StyledLogo = styled(Logo)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 44.5px 16px 32px 16px;
  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: none;
  }
`;
