import { Icon } from "../../../../../components/ui/Icon/Icon";
import { StyledSkeleton } from "../../../../../components/ui/Skeleton/components/SkeletonTableComponents.styled";
import { Tooltip } from "../../../../../components/ui/Tooltip/Tooltip";

import {
  StyledListItem,
  StyledListItemTitle,
  StyledListItemValue
} from "./Informations.styled";

export const RowInfo = ({
  title,
  tooltip = "",
  labelCenter = false,
  valueDirection = "row",
  children,
  loading = false
}) => (
  <StyledListItem>
    <StyledListItemTitle center={labelCenter}>
      {tooltip && (
        <Tooltip body={tooltip}>
          <Icon name={"info"} />
        </Tooltip>
      )}
      {!loading ? title : <StyledSkeleton width={100} height={14} />}
    </StyledListItemTitle>
    <StyledListItemValue column={valueDirection === "column"}>
      {!loading ? children : <StyledSkeleton width={100} height={14} />}
    </StyledListItemValue>
  </StyledListItem>
);
