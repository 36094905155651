import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Title } from "../../components/ui/Title/Title";
import { useDispatchedActions } from "../../hooks";
import { $api } from "../../services/AxiosInstance";
import { AllActions } from "../../store/reducers/AllActions";
import { getAllContent } from "../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "../../store/reducers/UserReducer/User.selectors";

import {
  StyledContainer,
  StyledContentBlock,
  StyledSiteNotFound,
  StyledTitleContainer
} from "./ServicePage.styled";
import { About } from "./components/About/About";
import { Alternative } from "./components/Alternative/Alternative";
import { Info } from "./components/Info/Info";

const ServicePage = () => {
  // **Props
  const { alias } = useParams();
  const { t } = useTranslation();

  // **Redux state
  const { site, statistic } = useSelector(getAllProxySite);
  const {
    topSites,
    pageContent: { current }
  } = useSelector(getAllContent);
  const { isUserAuthenticated } = useSelector(getAllUser);

  // **Local state
  const [isLoading, setIsLoading] = useState(alias !== site.data?.alias);

  // **Dispatch
  const {
    getProxySiteInfo,
    getProxySiteReviewsAmount,
    getTopSites,
    getAllPromocodesBySite,
    getProxySiteCompaints,
    setSiteViewStatistic
  } = useDispatchedActions();

  useEffect(() => {
    if (alias !== site.data?.alias) {
      if (!statistic.isStatisticSites.includes(alias)) {
        setSiteViewStatistic(alias);
      }

      getProxySiteInfo(alias);
      getProxySiteReviewsAmount(alias);
      getAllPromocodesBySite(alias);
      if (isUserAuthenticated) {
        getProxySiteCompaints({
          sort: {
            creationDate: "ASC"
          },
          siteId: alias,
          currentPage: 0,
          size: 10
        });
      }

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);

  useEffect(() => {
    if (!topSites.isDataLoaded) {
      getTopSites();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topSites.isDataLoaded]);

  return (
    <>
      <section>
        <StyledContainer>
          {!site.isError ? (
            <>
              <StyledTitleContainer>
                <Title tag={"h1"} loading={isLoading}>
                  {current?.data?.h1 || site.data?.name}
                </Title>
              </StyledTitleContainer>
              <StyledContentBlock>
                <Info />
                <About />
                <Alternative />
              </StyledContentBlock>
            </>
          ) : (
            <StyledSiteNotFound>
              {t("proxySitePage.notFound")}
            </StyledSiteNotFound>
          )}
        </StyledContainer>
      </section>
    </>
  );
};

export default ServicePage;

ServicePage.getServerSideState = async (store, params) => {
  let identifier = "";
  if (params.tag === "proxy-site-promo") {
    [, , identifier] = params.pathname.split("/").reverse();
  } else {
    [, identifier] = params.pathname.split("/").reverse();
  }

  const fetchParams = {
    languageCode: params.locale,
    params: {
      siteId: identifier
    },
    tag: params.tag,
    pathname: params.pathname,
    location: params.location
  };

  const { data: pageContent } = await $api.post(
    "/api/page/front/content",
    fetchParams
  );

  const { data: topSites } = await $api.get("api/front/site/top");

  const { data: goals } = await $api.get("api/goal/front/all", {
    params: { params: { languageCode: params.locale } }
  });
  const { data: goalsByGroups } = await $api.get("api/front/goal/group/all", {
    params: { languageCode: params.locale }
  });

  const { data: proxySiteInfo } = await $api.get(
    "api/front/site/proxy/description",
    { params: { id: identifier } }
  );
  const { data: reviewsAmount } = await $api.get(
    "api/front/site/reviews/amount",
    { params: { siteId: identifier } }
  );
  const { data: rating } = await $api.get("api/front/site/rating", {
    params: { siteId: identifier, step: "days" }
  });
  const { data: promocodes } = await $api.get("api/admin/promocode/site", {
    params: { siteId: identifier }
  });
  const { data: review } = await $api.get("api/front/site/review/sort", {
    params: {
      sort: "creationDate",
      siteId: identifier,
      page: 0,
      size: 4,
      showMore: 0
    }
  });
  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.locale,
      innerTag: params.location
    })
  );
  store.dispatch(AllActions.setTopSites(topSites));
  store.dispatch(AllActions.setGoals(goals));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(AllActions.setProxySiteInfo(proxySiteInfo));
  store.dispatch(AllActions.setProxySiteReviewsAmount(reviewsAmount));
  store.dispatch(
    AllActions.setProxySiteRating({
      content: rating,
      key: `${identifier}-days`
    })
  );
  store.dispatch(AllActions.setAllPromocodesBySite(promocodes));
  store.dispatch(
    AllActions.setProxySiteReviews({
      data: review,
      params: {
        sort: "creationDate",
        siteId: identifier,
        page: 0,
        size: 4,
        showMore: 0
      }
    })
  );
};
