import moment from "moment";

export const getChartData = (data = [], t) => {
  const currentMonth = new Date().getMonth() + 1;
  const monthes = t("proxySitePage.content.rating.labels", {
    returnObjects: true
  });
  const startArray = monthes?.slice(0, currentMonth);
  const endArray = monthes?.slice(currentMonth, 12);
  return {
    labels:
      data?.length !== 12
        ? [...Array(30)]
            .map((it, i) => {
              if (i === 0) {
                return moment().format("DD.MM");
              }

              return moment().add(-i, "day").format("DD.MM");
            })
            .reverse()
        : [...endArray, ...startArray],
    datasets: [
      {
        label: t("proxySitePage.content.rating.label"),
        data,
        fill: false,
        // backgroundColor: "transparent",
        borderColor: "#C8EE85",
        pointStyle: "line"
      }
    ]
  };
};
