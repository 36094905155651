import { useEffect, useState } from "react";

// eslint-disable-next-line import/namespace
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Select } from "../../../../../components/ui/Select/Select";
import { useDispatchedActions, useLangUrlDefault } from "../../../../../hooks";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getChartData } from "../../../../../utils/helpers";
import { TabTitle } from "../TabTitle/TabTitle";

import {
  StyledRatingContainer,
  StyledRatingFilter,
  StyledRatingFilterLabel,
  StyledRatingFilterSelectBox
} from "./Rating.styled";
import { RATING_CHART_OPTIONS } from "./ratingOptions";

Chart.register(...registerables);

export const Rating = ({ active }) => {
  // **Props
  const { alias } = useParams();
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const options = [
    {
      label: t("proxySitePage.content.rating.filters.month"),
      value: "days"
    },
    {
      label: t("proxySitePage.content.rating.filters.general"),
      value: "months"
    }
  ];

  // **Redux state
  const {
    rating: { data, isLoading }
  } = useSelector(getAllProxySite);
  // **Dispatch
  const { getProxySiteRating } = useDispatchedActions();
  // **Local state
  const [stepRating, setStepRating] = useState("days");

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    let promise = null;
    if (data?.key !== `${alias}-${stepRating}`) {
      promise = getProxySiteRating({ siteId: alias, step: stepRating });
    }
    return () => {
      if (promise) {
        promise.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias, stepRating]);

  useEffect(() => {
    setChartData(isLoading ? null : getChartData(data?.content, t));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, queryLang]);

  return (
    <StyledRatingContainer active={active}>
      <TabTitle
        title={t("proxySitePage.about.rating.title")}
        imgSrc={"/img/site-page/Rating.png"}
      />
      <StyledRatingFilter>
        <StyledRatingFilterLabel>
          {t("proxySitePage.about.rating.color")}
        </StyledRatingFilterLabel>
        <StyledRatingFilterSelectBox>
          <Select
            options={options}
            value={stepRating}
            onChange={setStepRating}
            size={"md"}
          />
        </StyledRatingFilterSelectBox>
      </StyledRatingFilter>
      {!!chartData && (
        <Line redraw={true} data={chartData} options={RATING_CHART_OPTIONS} />
      )}
    </StyledRatingContainer>
  );
};
